import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { addNotification, showNotification } from '../../../api/redux/notificationAction'
import projectDataFlows from '../../../data.json'
import CBreadCrumb from '../../../UI-components/BreadCrumb/index.js'
import Tabs from '../../components-examples/Tabs'
import { setProjectInfoKey } from '../../redux/FrameWorkReducer'
import { getCurrentContextuserIdDetails, isJson } from '../Admin/AccountUtility'
import FlipSwitch from '../AppDetail/FlipSwitch'
import Card from '../ImageCard'
import Group from '../ImageCard/Group'
import PopUp from '../PopUP'
import { NormalButton } from '../ReusableCode/EmlyButton'
import '../ReusableCode/emlyMain.css'
import { LoaderBGWithinComponent } from '../utils'
import { groupProjects, manageOrgChoice, sUserCredentials } from '../utils/commonUtilityFunctions'
import MainErrorBoundaryParent from '../utils/ErrorBoundary/MainErrorBoundaryParent'
import { OverLayLodingInsideComponent } from '../utils/Loaders'
import CpInvite from './CpInvite'
import { ErrorScreen } from './ErrorScreen'
import './index.css'
import Overlay from './overlay'
import { ProjectCreationForm } from './ProjectCreationForm.js'
import Start from './Start'
import { BackNavigation } from '../../../UI-components/Back/BackNavigation.js'
import { jwtDecode } from 'jwt-decode'

const isObjectEqual = objB => {
  const keysA = Object.keys({
    Title: 'Start Blank Project',
    flow_id: 'blank_project',
    learn: true,
    industry: [],
    process_flow: []
  })
  if (typeof objB !== 'object' || objB === null) {
    return false
  }
  console.log(
    keysA.every(key => objB.hasOwnProperty(key)),
    'check flow failures',
    objB
  )
  return keysA.every(key => objB.hasOwnProperty(key))
}

class Welcome extends React.Component {
  constructor() {
    super()

    this.state = {
      sampleProjects: [],
      selected: null,
      formDialogue: false,
      groupLoading: false,
      status: null,
      projectLoading: false,
      overlay: false,
      showWelcome: false,
      welcomeOverlay: false,
      instance: null,
      orgId: null,
      instanceState: null,
      showButtons: null,
      selectedSampleLabel: null,
      orgIdName: null,
      orgCreated: false,
      error: null,
      projectCreation: null,
      process_flow: null,
      projectID: null,
      errorMain: false,
      project_flow: null,
      loadingSamples: true,
      project_error: false,
      sampleProjectJson: [],
      floWprojects: null,
      floWprojectsLoad: null,
      flowGroups: [],
      isLoadingUPlan: false,
      uPCreation: false,
      projectCreated: [],
      pageRecords: { start: 0, max: 1000 },
      wGroupCreated: [],
      isAddedDone: false,
      showBuyCourse: false,
      showBuyGroupInfo: null
    }
    this.createP = this.createP.bind(this)
    this.aPTGroup = this.aPTGroup.bind(this)
    this.iUTProject = this.iUTProject.bind(this)
  }

  checkIsAddIntrestedAddons = (payload, silent) => {
    if (this.props.api && this.props.api.keycloakToken()) {
      let jwt_token_object = {}

      try {
        const decoded = jwtDecode(this.props.api.keycloakToken())
        jwt_token_object = decoded
      } catch (error) { console.error('Error while decoding the jwt token at dashboard.js', error) }
      if (jwt_token_object && jwt_token_object.may_buy_addon_prodid) {
        const addOnProducts =
          this.props.isSubscriptionCheck &&
            !this.props.isSubscriptionCheck.isLoading &&
            this.props.isSubscriptionCheck.addOnProducts
            ? this.props.isSubscriptionCheck.addOnProducts
            : []
        if (addOnProducts && addOnProducts.length > 0 && !addOnProducts.includes(jwt_token_object.may_buy_addon_prodid)) {
          const groupToBuy = Object.entries(payload).find(([key, item]) => item.spid === jwt_token_object.may_buy_addon_prodid)
          const flattenObject = groupToBuy
            ? { ...groupToBuy[1], key: groupToBuy[0] }
            : null;
          this.setState({ showBuyCourse: true, showBuyGroupInfo: flattenObject })
          if (flattenObject && flattenObject.key && (silent === undefined || silent === false))
            this.navigateTo(`/projects/flows/learn/${flattenObject['key']}`)
        }
      }
    }
  }

  componentDidMount() {
    const isProject = this.props.projectComponent
    this.setState({
      welcomeOverlay: isProject !== undefined ? false : true,
      showWelcome: isProject !== undefined ? true : false,
      projectCreation: isProject === undefined ? false : true
    })

    const current_logged_user = getCurrentContextuserIdDetails(this.props.myUserAccountInfo.current_logged_user)

    if (current_logged_user.orgId) {
      if (isProject) {
        this.refreshGroups(true)
        this.getProjectGroups()
      }
      const url = `${window.REACT_APP_GATEWAY_ORG_SPECFIC_PROJECTS}${current_logged_user.orgId}/hubflow_emly_org_v1.json`
      const api = this.props.api
      if (api == null) return
      const action = api.getOrgSpecficProjectCreationFlowsRSAA(url)
      Promise.resolve(this.props.dispatch(action)).then(response => {
        if (response.type.endsWith('SUCCESS')) {
          const orgSpecficProjects = response && response.payload ? response.payload : []
          this.AfterSpecficProjectCall(orgSpecficProjects)
        } else {
          this.AfterSpecficProjectCall([])
        }
      })
    } else {
      this.AfterSpecficProjectCall([])
    }

    this.setState({ instanceState: 'first' })
    if (this.props.projectComponent === undefined) {
      this.fetchUserOrganziationInfo()
    }
  }

  refreshGroups = (silent) => {

    if (!silent) {
      this.setState({ groupLoading: true })
    }

    const current_logged_user = getCurrentContextuserIdDetails(this.props.myUserAccountInfo.current_logged_user)
    const { api } = this.props
    if (api == null) return
    const action = api.createListGroupsRSAA(current_logged_user, { start: 0, max: -1 }, null)
    Promise.resolve(this.props.dispatch(action)).then(response => {
      if (!silent) {
        this.setState({ groupLoading: false })
      }
    })
  }

  buyCourse = (planId, successUrl, cancelUrl) => {
    this.setState({ isLoadingUPlan: true })
    let current_logged_user = getCurrentContextuserIdDetails(this.props.myUserAccountInfo.current_logged_user)
    const bodyparams = {
      successUrl: successUrl ? successUrl : `${window.REACT_APP_SELF_URL}/organizations/billings?paymentSuccess=78dfr030`,
      cancelUrl: cancelUrl ? cancelUrl : `${window.REACT_APP_SELF_URL}/organizations/billings?paymentFail=s67830-09`,
      productId: planId,
      productQuantity: 1,
      paymentMode: `payment`
    }

    const { api } = this.props
    if (api == null) return
    Promise.resolve(this.props.dispatch(api.createCheckOutSessionRSAA(current_logged_user, bodyparams)))
      .then(response => {
        const t = response.type.toLowerCase()
        const hasError = t.endsWith('failure')
        if (!hasError) {
          window.location = response.payload.stripe && response.payload.stripe.url ? response.payload.stripe.url : '/'
        } else {
          this.props.dispatch(
            addNotification(
              `${response.payload && response.payload.response && response.payload.response.message
                ? response.payload.response.message
                : window.REACT_APP_ERROR_MESSAGE
              }`,
              'danger',
              false
            )
          )
          this.props.dispatch(showNotification(true))
        }
      })
      .then(() => {
        this.setState({ isLoadingUPlan: false })
      })
  }

  getProjectGroups = () => {
    const currentPlan =
      (this.props.isSubscriptionCheck &&
        !this.props.isSubscriptionCheck.isLoading &&
        this.props.isSubscriptionCheck.subscribedProduct &&
        this.props.isSubscriptionCheck.subscribedProduct.name
        ? this.props.isSubscriptionCheck.subscribedProduct.name
        : 'NO_PLAN').toUpperCase()

    const api = this.props.api
    if (api == null) return
    const action = api.getProjectGroupsRSAA()
    Promise.resolve(this.props.dispatch(action))
      .then(response => {
        const hasError = response.type.toLowerCase().endsWith('failure')
        if (!hasError) {
          if (response.payload) {
            this.setState({ flowGroups: response.payload })
            if (currentPlan === 'LEARNER')
              this.checkIsAddIntrestedAddons(response.payload, false)
          }
        }
      })
      .catch(err => {
        console.log('some Issues occur while trying to fetch groups detail.', JSON.stringify(err))
      })
  }

  AfterSpecficProjectCall = currentProjects => {
    const url = window.REACT_APP_GATEWAY_PROJECT_FLOWS
    const api = this.props.api
    if (api == null) return
    const action = api.getProjectCreationFlowsRSAA(url)
    Promise.resolve(this.props.dispatch(action)).then(response => {
      const hasError = response.type.toLowerCase().endsWith('failure')
      if (!hasError) {
        const responseProjects = response && response.payload ? response.payload : []
        const allProjectsFlows = [...currentProjects, ...responseProjects]
        const data = allProjectsFlows.map(obj => {
          const result = isObjectEqual(obj)
          if (result) {
            return obj
          }
        })
        const newData = data.filter(i => i !== null && i !== undefined)
        this.setState({ sampleProjectJson: newData.length === 0 ? projectDataFlows : newData })
      } else {
        this.setState({ sampleProjectJson: projectDataFlows })
      }
    })
  }

  //3rd call to create organization
  organizationCreation = bodyparams => {
    const { api } = this.props
    if (api == null) return
    Promise.resolve(this.props.dispatch(api.createUserOrganizationRSAA(bodyparams))).then(response => {
      const t = response.type.toLowerCase()
      const hasError = t.endsWith('failure')
      if (!hasError) {
        if (
          response.payload &&
          response.payload.subscriptionStatus &&
          response.payload.subscriptionStatus === 'BUY_PLAN'
        ) {
          this.checkoutSession(bodyparams, response.payload.organization.id)
        } else {
          window.location.reload('/')
        }
      } else {
        const errorMessage =
          response.payload && response.payload.message ? response.payload.message : window.REACT_APP_ERROR_MESSAGE
        this.props.dispatch(addNotification(errorMessage, 'danger', false))
        this.props.dispatch(showNotification(true))
        if (this.state.projectCreation === false) {
          this.setState({ error: errorMessage })
        }
      }
    })
  }

  checkoutSession = (params, orgId) => {
    let current_logged_user = { orgId: orgId }
    const bodyparams = {
      successUrl: `${window.REACT_APP_SELF_URL}/organizations/billings?paymentSuccess=78dfr030`,
      cancelUrl: `${window.REACT_APP_SELF_URL}/organizations/billings?paymentFail=s67830-09`,
      productId: params.planId,
      paymentMode: `subscription`
    }

    const { api } = this.props
    if (api == null) return
    Promise.resolve(this.props.dispatch(api.createCheckOutSessionRSAA(current_logged_user, bodyparams))).then(
      response => {
        const t = response.type.toLowerCase()
        const hasError = t.endsWith('failure')
        if (!hasError) {
          window.location = response.payload.stripe && response.payload.stripe.url ? response.payload.stripe.url : '/'
        } else {
          this.props.dispatch(
            addNotification(
              `${response.payload && response.payload.response && response.payload.response.message
                ? response.payload.response.message
                : 'We are unable to Process, Please try later'
              }`,
              'danger',
              false
            )
          )
          this.props.dispatch(showNotification(true))
          window.location.reload('/')
        }
      }
    )
  }

  navigateTo = path => {
    const { history } = this.props
    history.push(path)
  }

  handleChange = (id, label) => {
    let orgDetails = {}
    if (this.state.orgCreated) {
      orgDetails = { orgIdName: this.state.orgIdName, orgId: this.state.orgId }
    } else {
      orgDetails = {
        orgIdName: getCurrentContextuserIdDetails(this.props.myUserAccountInfo.current_logged_user).orgIdName,
        orgId: getCurrentContextuserIdDetails(this.props.myUserAccountInfo.current_logged_user).orgId
      }
    }

    const userId = this.props.myUserAccountInfo.current_logged_user
    const switchTo = {
      orgId: orgDetails.orgId,
      orgIdName: orgDetails.orgIdName,
      spaceId: id,
      spaceIdName: label
    }

    sUserCredentials(window.localStorage.defaultProfileSetting, userId, switchTo, true)
    setTimeout(() => {
      window.location = `/${orgDetails.orgId}/${id}/projects/${id}`
    }, 20)
  }

  getOrgId = () => {
    if (this.state.projectCreation) {
      const userId = this.props.myUserAccountInfo.current_logged_user
      let defaultProfileSetting = isJson(window.localStorage.defaultProfileSetting)
      let cacheObject = defaultProfileSetting[userId]
      return cacheObject['activeOrganization']
    } else {
      return this.state.orgId
    }
  }

  fetchUserOrganziationInfo = () => {
    const fetchurl = `${window.REACT_APP_GATEWAY_API_URL}/profile/userRoles/user`
    fetch(fetchurl, {
      method: 'GET',
      headers: Object.assign(
        { 'Content-Type': 'application/json' },
        { Authorization: `Bearer ${this.props.api.keycloakToken()}` }
      )
    })
      .then(response => {
        return response.json()
      })
      .then(response => {
        const tokenDetails = JSON.parse(atob(this.props.api.keycloakToken().split('.')[1]))
        if (response.rolesUser === undefined) {
          this.organizationCreation({
            title: tokenDetails.org_name,
            planId: tokenDetails.prodId,
            description: 'new_user_org_description',
            owner: this.props.myUserAccountInfo.current_logged_user,
            name: 'new_user_org_name'
          })
          this.setState({ instance: 1 })
        } else {
          const firstOrg = response.rolesUser.organizations[0]
          this.setState({ instance: 1 })
          this.setState({ instanceState: 'second' })
          this.setState({ orgCreated: true, orgId: firstOrg.id, orgIdName: firstOrg.name })
        }
      })
      .catch(err => {
        if (this.state.projectCreation === false) {
          this.setState({ error: 'Something went wrong' })
        }
      })
  }

  createNormalSpaceCreation = spaceInfo => {
    this.setState({ formDialogue: true })
    const orgId = this.getOrgId()
    this.setState({ projectLoading: true })
    let current_logged_user = this.props.myUserAccountInfo.current_logged_user

    const body = {
      title: spaceInfo['title'],
      flowId: spaceInfo['flowId'],
      advancedMode: false,
      projectObjectives: JSON.parse(
        JSON.stringify(
          this.state.process_flow.map((i, id) => {
            return { sequence: id, objective: i.objective }
          })
        )
      ),
      description: spaceInfo['space_desc'],
      createdOn: new Date(),
      createdBy: current_logged_user
    }

    const { api } = this.props
    if (api == null) return
    Promise.resolve(this.props.dispatch(api.createProjectFlowRSAA(orgId, body))).then(response => {
      const t = response.type.toLowerCase()
      const hasError = t.endsWith('failure')
      if (!hasError) {
        if (response && response.payload && response.payload.space && response.payload.space.id) {
          this.handleChange(response.payload.space.id, spaceInfo['title'])
        } else {
          window.location = `/`
        }
      } else {
        this.setState({ projectLoading: false, formDialogue: false })
        this.props.dispatch(
          addNotification(
            `${response.payload && response.payload.response && response.payload.response.message
              ? response.payload.response.message
              : 'We are unable to Process, Please try later'
            }`,
            'danger',
            false
          )
        )
        this.props.dispatch(showNotification(true))
      }
    })
  }

  createNewSpace = (spaceInfo) => {
    if (spaceInfo['showAdvanced'] === true && spaceInfo['groupId'] && spaceInfo['roleName']) {
      Promise.resolve(this.getGroupMembers(spaceInfo['groupId'])).then(response => {
        const t = response.type.toLowerCase()
        const hasError = t.endsWith('failure')
        if (!hasError) {
          this.createGroupTSBulkFlow(spaceInfo, response.payload.members)
        } else {
          this.props.dispatch(
            addNotification(
              `${response.payload && response.payload.response && response.payload.response.message
                ? response.payload.response.message
                : window.REACT_APP_ERROR_MESSAGE
              }`,
              'danger',
              false
            )
          )
          this.props.dispatch(showNotification(true))
        }
      })
    } else {
      this.createNormalSpaceCreation(spaceInfo)
    }
  }

  createGroupTSBulkFlow = (spaceInfo, members) => {
    const current_logged_user = getCurrentContextuserIdDetails(this.props.myUserAccountInfo.current_logged_user)
    const groupName = `${spaceInfo['title']}_${spaceInfo['groupName']}`
    const groupdesc = `${spaceInfo['space_desc']}`

    const group_Data = {
      title: groupName,
      description: groupdesc,
      tags: ['PROJECTS'],
      orgId: current_logged_user.orgId,
      active: 'true'
    }

    const { api } = this.props
    if (api == null) return
    const that = this
    Promise.resolve(this.props.dispatch(api.createGroupRSAA(current_logged_user, group_Data))).then(response => {
      const t = response.type.toLowerCase()
      const hasError = t.endsWith('failure')
      if (!hasError) {
        if (response && response.payload && response.payload.group && response.payload.group.id) {
          this.setState({ uPCreation: true, formDialogue: true, wGroupCreated: response.payload.group })
          const spaceInf = { ...spaceInfo, groupId: response.payload.group.id }
          this.bulkFlow(spaceInf, members)
        } else {
        }
      } else {
        this.dispatchError(response)
        this.setState({ projectLoading: false })
      }
    })
  }

  getGroupMembers = groupId => {
    const current_logged_user = getCurrentContextuserIdDetails(this.props.myUserAccountInfo.current_logged_user)
    const { api } = this.props
    if (api == null) return
    return this.props.dispatch(
      api.createGetGroupMembersRSAA(current_logged_user, groupId, 'user', this.state.pageRecords)
    )
  }

  refreshOrgProject = () => {
    const current_logged_user = getCurrentContextuserIdDetails(this.props.myUserAccountInfo.current_logged_user)
    const { api } = this.props
    if (api == null) return
    return this.props.dispatch(api.getAllProjectsOfOrgRSAA(current_logged_user))
  }

  bulkFlow = async (spaceInfo, members) => {
    const orgId = this.getOrgId()
    const current_logged_user = this.props.myUserAccountInfo.current_logged_user
    const cUser = getCurrentContextuserIdDetails(this.props.myUserAccountInfo.current_logged_user)

    const body = {
      flowId: spaceInfo['flowId'],
      advancedMode: false,
      projectObjectives: JSON.parse(
        JSON.stringify(
          this.state.process_flow.map((i, id) => {
            return { sequence: id, objective: i.objective }
          })
        )
      ),
      description: spaceInfo['space_desc'],
      createdOn: new Date(),
      createdBy: current_logged_user
    }

    const promises = []
    if (members) {
      let membersAdded = []
      const that = this
      for (let i = 0; i < members.length; i++) {
        const it = members[i]
        const spaceName = `${i + 1}. ${spaceInfo['title']} ${it.name}`
        const displayName = `${i + 1}. ${it.name}`
        membersAdded.push({ status: 'LOAD', content: {}, title: displayName })
        this.setState({ projectCreated: membersAdded })
        try {
          promises.push(
            await new Promise(function (resolve, reject) {
              that.createP(
                cUser,
                it,
                spaceInfo['roleName'],
                orgId,
                { ...body, title: spaceName },
                spaceInfo['groupId'],
                displayName,
                resolve,
                reject,
                false
              )
            })
          )
        } catch (error) {
          promises.push(error)
        }
      }

      Promise.allSettled(promises)
        .then(it => {
          let membersAdded = []
          it.map(item => {
            if (item.value['status'] === 'fulfilled') {
              membersAdded.push({
                status: 'PASS',
                content: item.value.data,
                title: item.value['title'],
                step: item.value.step
              })
            } else {
              membersAdded.push({
                status: 'FAIL',
                content: item.value.data,
                title: item.value['title'],
                step: item.value.step
              })
            }
          })
          this.setState({ projectCreated: membersAdded, isAddedDone: true })
        })
        .then(it => {
          this.setState({ formDialogue: false })
          this.refreshOrgProject()
        })
    }
  }

  createP(cUser, it, roleName, orgId, body, groupId, displayName, resolve, reject, isShow) {
    const { api } = this.props
    if (api == null) return
    const that = this
    Promise.resolve(this.props.dispatch(api.createProjectFlowRSAA(orgId, body))).then(response => {
      const t = response.type.toLowerCase()
      const hasError = t.endsWith('failure')
      if (!hasError) {
        if (response && response.payload && response.payload.space && response.payload.space.id) {
          that.aPTGroup(
            cUser,
            it,
            roleName,
            groupId,
            response.payload.space.id,
            orgId,
            displayName,
            resolve,
            reject,
            false
          )
        } else {
          reject({
            status: 'rejected',
            title: displayName,
            step: 1,
            data: { cUser, it, roleName, orgId, body, groupId }
          })
        }
      } else {
        if (isShow) this.dispatchError(response)
        reject({ status: 'rejected', title: displayName, step: 1, data: { cUser, it, roleName, orgId, body, groupId } })
      }
    })
  }

  aPTGroup(cUser, it, roleName, groupId, projectId, orgId, displayName, resolve, reject, isShow) {
    const body = {
      entityIds: [projectId],
      type: 'project'
    }
    const { api } = this.props
    if (api == null) return
    Promise.resolve(this.props.dispatch(api.createAddGroupEntityRSAA(cUser, groupId, body))).then(response => {
      const t = response.type.toLowerCase()
      const hasError = t.endsWith('failure')
      if (!hasError) {
        this.iUTProject(it, roleName, projectId, orgId, displayName, resolve, reject, false)
      } else {
        if (isShow) this.dispatchError(response)
        reject({
          status: 'rejected',
          step: 2,
          title: displayName,
          data: { cUser, it, roleName, groupId, projectId, orgId }
        })
      }
    })
  }

  iUTProject(it, roleName, projectId, orgId, displayName, resolve, reject, isShow) {
    let updatedBody = {
      email: it.email.trim(),
      orgId: orgId,
      roleName: roleName,
      spaceId: projectId,
      permissions: []
    }

    const { api } = this.props
    if (api == null) return
    Promise.resolve(this.props.dispatch(api.createInviteUserToProjectRSAA(updatedBody, orgId, projectId))).then(
      response => {
        const t = response.type.toLowerCase()
        const hasError = t.endsWith('failure')
        if (!hasError) {
          resolve({ status: 'fulfilled', step: 1, title: displayName, data: { it, roleName, projectId, orgId } })
        } else {
          if (isShow) this.dispatchError(response)
          reject({ status: 'rejected', step: 3, title: displayName, data: { it, roleName, projectId, orgId } })
        }
      }
    )
  }

  dispatchError = response => {
    this.props.dispatch(
      addNotification(
        response && response.payload && response.payload.response && response.payload.response.message
          ? response.payload.response.message
          : window.REACT_APP_ERROR_MESSAGE,
        'error',
        false
      )
    )
    this.props.dispatch(showNotification(true))
  }

  getRouterFlowGroupId = props => {
    const _props = props != null ? props : this.props
    const {
      match: {
        params: { flowGroupId }
      }
    } = _props
    return flowGroupId || null
  }

  getRouterFlowTypeId = props => {
    const _props = props != null ? props : this.props
    const {
      match: {
        params: { flowType }
      }
    } = _props
    return flowType || null
  }

  render() {
    const { sampleProjects, status, overlay, showWelcome, welcomeOverlay, instance, instanceState, showButtons } =
      this.state
    return (
      <React.Fragment>
        <div className="Welcome">
          {this.state.errorMain && <MainErrorBoundaryParent error={this.state.errorMain}></MainErrorBoundaryParent>}
          <>
            {showWelcome === false ? (
              welcomeOverlay && (
                <Start
                  overlay={welcomeOverlay}
                  title={'Setting Up your instance'}
                  instance={instance}
                  instanceState={instanceState}
                  onStart={() => this.setState({ showWelcome: true })}
                  error={this.state.error}
                  persisted={this.props.persisted}
                  onResolve={() => {
                    this.setState({ error: null })
                    this.fetchUserOrganziationInfo()
                  }}
                />
              )
            ) :

              <CreateProjectPanel
                process_flow={this.state.process_flow}
                setFlow={value => this.setState({ process_flow: value })}
                theme={this.props.theme}
                cancelProjectCreation={this.props.cancelProjectCreation}
                error={this.state.error}
                onResolve={() => this.setState({ project_error: false })}
                showButtons={showButtons}
                sampleProjects={sampleProjects}
                project_error={this.state.project_error}
                createRecord={value => this.createNewSpace(value)}
                sampleProjectJson={this.state.sampleProjectJson}
                tabView={this.props.tabView}
                flowGroups={this.state.flowGroups}
                navigateTo={this.navigateTo}
                myProfile={this.props.myProfile}
                isSubscriptionCheck={this.props.isSubscriptionCheck}
                buyCourse={this.buyCourse}
                isLoadingUPlan={this.state.isLoadingUPlan}
                dispatch={this.props.dispatch}
                getAvailableUserRoles={this.props.getAvailableUserRoles}
                fetchAllgroups={this.props.fetchAllgroups}
                uPCreation={this.state.uPCreation}
                projectCreated={this.state.projectCreated}
                formDialogue={this.state.formDialogue}
                groupLoading={this.state.groupLoading}
                closes={() => this.setState({ uPCreation: false })}
                myUserAccountInfo={this.props.myUserAccountInfo.current_logged_user}
                createProject={this.createP}
                addProjectToGroup={this.aPTGroup}
                inviteUsertoProject={this.iUTProject}
                wGroupCreated={this.state.wGroupCreated}
                isAddedDone={this.state.isAddedDone}
                isFlowActive={this.props.isFlowActive}
                RgroupId={this.getRouterFlowGroupId()}
                FlowTypeKey={this.getRouterFlowTypeId()}
                showBuyCourse={this.state.showBuyCourse}
                showBuyGroupInfo={this.state.showBuyGroupInfo}
                hideShowBuyCourse={() => this.setState({ showBuyCourse: false })}
                checkIsAddIntrestedAddons={this.checkIsAddIntrestedAddons}
                refreshGroups={this.refreshGroups}
              />
            }

            {overlay && (
              <Overlay
                onResolve={() => {
                  this.setState({ error: null, overlay: null })
                }}
                error={this.state.error}
                tipMessage="Goback & try to select other sample project"
                overlay={overlay}
                status={status}
                instanceState={instanceState}
              />
            )}
          </>
        </div>
      </React.Fragment>
    )
  }
}

Welcome.propTypes = {
  api: PropTypes.object,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
  api: state.api,
  apiError: state.apiError,
  myProfile: state.myProfile,
  myHelpLinks: state.myHelpLinks,
  datoinServices: state.datoinServices,
  bgStatus: state.bgStatus,
  myApps: state.myApps,
  myTemplates: state.myTemplates,
  myReccTemplates: state.myReccTemplates,
  checkUberDeployment: state.checkUberDeployment,
  theme: state.theme,
  myUserAccountInfo: state.myUserAccountInfo,
  isSubscriptionCheck: state.isSubscriptionCheck,
  getAvailableUserRoles: state.getAvailableUserRoles,
  fetchAllgroups: state.fetchAllgroups,
  projectFlowInfoKey: state.emlyFrameWork.projectFlowInfoKey,
  persisted: state.userProfilePersisted,
  projects: state.myProjects
})

export default withRouter(connect(mapStateToProps)(Welcome))

const CreateProjectPanel = ({
  theme,
  setFlow,
  createRecord,
  project_error,
  onResolve,
  error,
  sampleProjectJson,
  cancelProjectCreation,
  tabView,
  flowGroups,
  navigateTo,
  myProfile,
  isSubscriptionCheck,
  buyCourse,
  isLoadingUPlan,
  dispatch,
  getAvailableUserRoles,
  fetchAllgroups,
  uPCreation,
  projectCreated,
  formDialogue,
  groupLoading,
  closes,
  myUserAccountInfo,
  createProject,
  addProjectToGroup,
  inviteUsertoProject,
  wGroupCreated,
  projectFlowInfoKey,
  isAddedDone,
  isFlowActive,
  RgroupId,
  FlowTypeKey,
  showBuyCourse,
  hideShowBuyCourse,
  showBuyGroupInfo,
  checkIsAddIntrestedAddons,
  refreshGroups
}) => {
  const da =
    sampleProjectJson.length > 0
      ? sampleProjectJson
        .filter(i => {
          if (i.flow_id === 'blank_project') {
            return true
          } else if (i.flow_id !== 'blank_project' && i.released === true) {
            return true
          }
          return false
        })
        .map(item => {
          return {
            value: item.process_flow,
            label: item.Title,
            ...item
          }
        })
      : []

  const [ErrorPopUp, setErrorPopUp] = React.useState(false)

  return (
    <div className="Welcome" style={{ padding: '0px' }} data-theme={theme.action.theme}>
      {project_error && <ErrorScreen buttonText={'Okay'} onResolve={onResolve} error={error} />}

      {ErrorPopUp && (
        <PopUp
          width={'50%'}
          loadingCancel={true}
          buttonLabel={'Okay'}
          bottomButtonCancel={false}
          onCancel={() => {
            setErrorPopUp(false)
          }}
          onExecution={() => {
            setErrorPopUp(false)
          }}
        >
          <h3 style={{ fontWeight: 'bold', textAlign: 'center' }} className="heading-part">
            Please fill all fields.
          </h3>
        </PopUp>
      )}

      <ProjectMain
        cancelProjectCreation={cancelProjectCreation}
        data={da}
        tabView={tabView}
        flowGroups={flowGroups}
        theme={theme}
        dispatch={dispatch}
        createRecord={createRecord}
        navigateTo={navigateTo}
        myProfile={myProfile}
        isSubscriptionCheck={isSubscriptionCheck}
        buyCourse={buyCourse}
        isLoadingUPlan={isLoadingUPlan}
        setFlow={setFlow}
        getAvailableUserRoles={getAvailableUserRoles}
        fetchAllgroups={fetchAllgroups}
        uPCreation={uPCreation}
        projectCreated={projectCreated}
        formDialogue={formDialogue}
        groupLoading={groupLoading}
        closes={closes}
        myUserAccountInfo={myUserAccountInfo}
        createProject={createProject}
        inviteUsertoProject={inviteUsertoProject}
        addProjectToGroup={addProjectToGroup}
        wGroupCreated={wGroupCreated}
        projectFlowInfoKey={projectFlowInfoKey}
        isAddedDone={isAddedDone}
        isFlowActive={isFlowActive}
        RgroupId={RgroupId}
        FlowTypeKey={FlowTypeKey}
        showBuyCourse={showBuyCourse}
        hideShowBuyCourse={hideShowBuyCourse}
        showBuyGroupInfo={showBuyGroupInfo}
        checkIsAddIntrestedAddons={checkIsAddIntrestedAddons}
        refreshGroups={refreshGroups}
      />
    </div>
  )
}

const isLocked = (practice, subscription) => {
  const type = practice.learn === true ? "Learn" : "Build"
  const subscriptionPlan =
    subscription && !subscription.isLoading && subscription.subscribedProduct && subscription.subscribedProduct.name
      ? subscription.subscribedProduct.name
      : 'NO_PLAN'

  const addOnProducts =
    subscription && !subscription.isLoading && subscription.addOnProducts ? subscription.addOnProducts : []

  if (type === 'Build') {
    if (
      subscriptionPlan.toUpperCase() === 'PROFESSIONAL' &&
      practice &&
      practice.plan &&
      practice.plan.includes(subscriptionPlan)
    ) {
      return false
    } else if (
      subscriptionPlan.toUpperCase() !== 'PROFESSIONAL' &&
      practice &&
      practice.plan &&
      practice.plan.includes(subscriptionPlan)
    ) {
      return false
    } else {
      return true
    }
  } else {
    if (addOnProducts && practice && practice.spid && addOnProducts.includes(practice.spid)) {
      return false
    } else {
      return true
    }
  }
}

const ProjectMain = ({
  data,
  tabView,
  flowGroups,
  theme,
  dispatch,
  createRecord,
  navigateTo,
  myProfile,
  isSubscriptionCheck,
  buyCourse,
  isLoadingUPlan,
  setFlow,
  cancelProjectCreation,
  getAvailableUserRoles,
  fetchAllgroups,
  uPCreation,
  projectCreated,
  formDialogue,
  groupLoading,
  closes,
  myUserAccountInfo,
  createProject,
  inviteUsertoProject,
  addProjectToGroup,
  wGroupCreated,
  projectFlowInfoKey,
  isAddedDone,
  isFlowActive,
  RgroupId,
  FlowTypeKey,
  showBuyCourse,
  hideShowBuyCourse,
  showBuyGroupInfo,
  checkIsAddIntrestedAddons,
  refreshGroups
}) => {

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const [learnProjects, setLearnProjects] = useState([])
  const [buildProjects, setBuildProjects] = useState([])
  const [active, setActive] = useState(capitalizeFirstLetter(FlowTypeKey))

  const [projectPopup, setProjectPopup] = React.useState(false)
  const [upCreate, setupCreate] = React.useState(false)
  const [flowTitle, setFlowTitle] = React.useState(null)
  const [flowDescrption, setDescription] = React.useState(null)
  const [currenItem, setCurrenItem] = React.useState([])
  const [flowId, setFlowId] = React.useState(null)
  const [isBuild, setIsBuild] = React.useState(true)
  const [showAvailable, setShowAvailable] = useState(false)
  const [buyIsLoading, setBuyIsLoading] = React.useState(false)

  const handleSwitch = value => {
    setShowAvailable(value)
  }

  const current_logged_user = getCurrentContextuserIdDetails(myUserAccountInfo.current_logged_user)

  useEffect(() => {
    if (FlowTypeKey) {
      setActive(capitalizeFirstLetter(FlowTypeKey))
    }
  }, [FlowTypeKey])

  useEffect(() => {
    if (isFlowActive) {
      setActive(isFlowActive)
    }
  }, [])

  useEffect(() => {
    if (data && data.length !== 0 && !projectPopup) {
      const groups = groupProjects(data, 'learn', 'noGroup')
      setLearnProjects(
        groups['true'].filter(
          practice => practice.plan && practice.plan.length > 0 && practice.spid && practice.spid !== ''
        )
      )
      setBuildProjects(groups['false'].filter(practice => practice.plan && practice.plan.length > 0))
    }
  }, [data, active])

  const checkoutLearnGroup = key => {
    navigateTo(`/projects/flows/learn/${key}`)
    checkIsAddIntrestedAddons(flowGroups, true)
  }

  const checkoutBuildGroup = key => {
    navigateTo(`/projects/flows/build/${key}`)
    checkIsAddIntrestedAddons(flowGroups, true)
  }

  const backFromProject = () => {
    if (RgroupId) {
      navigateTo(`/projects/flows/${active.toLowerCase()}`)
    } else {
      cancelProjectCreation()
    }
  }

  const currentAvailedPlan =
    isSubscriptionCheck &&
      !isSubscriptionCheck.isLoading &&
      isSubscriptionCheck.subscribedProduct &&
      isSubscriptionCheck.subscribedProduct.name
      ? isSubscriptionCheck.subscribedProduct.name
      : 'NO_PLAN'

  const openProjectPopup = (value, label, id, description, card, isBuild) => {
    setFlow(value)
    setFlowId(id)
    setFlowTitle(label)
    setProjectPopup(true)
    closes()
    setupCreate(true)
    setDescription(description)
    setCurrenItem(card)
    setIsBuild(isBuild)
  }

  const addInLStorage = value => {
    manageOrgChoice(window.localStorage.OrgChoice, current_logged_user.orgId, 'HubFLow', value)
  }

  const getIsLocked = practice => {
    return isLocked(practice, isSubscriptionCheck)
  }

  const getBreadCrumb = isGroup => {
    let bArray = [
      {
        title: 'Organizations',
        path: null,
        dispatch: null,
        active: false,
        func: () => navigateTo('/organizations')
      },
      {
        title: 'My Projects',
        path: null,
        dispatch: null,
        active: false,
        func: () => navigateTo('/projects')
      }
    ]

    if (isGroup !== null) {
      bArray.push(
        {
          title: `${active} Flows`,
          path: null,
          dispatch: null,
          active: false,
          func: () => {
            navigateTo(`/projects/flows/${capitalizeFirstLetter(active)}`)
          }
        },
        {
          title: flowGroups[RgroupId] && flowGroups[RgroupId].title,
          path: null,
          dispatch: null,
          active: true,
          func: null
        }
      )
    } else {
      bArray.push({
        title: `${active} Flows`,
        path: null,
        dispatch: null,
        active: true,
        func: () => null
      })
    }

    return bArray
  }

  const tabData = isSubscriptionCheck &&
    !isSubscriptionCheck.isLoading ? [
    {
      name: 'Learn Flows',
      id: 'Learn',
      icon: 'LEARN_FLOW',
      onClickTab: value => {
        navigateTo(`/projects/flows/learn`)
        addInLStorage(value)
      },
      component: (
        <ProjectsLearn
          RgroupId={RgroupId}
          checkoutLearnGroup={checkoutLearnGroup}
          data={learnProjects}
          openProjectPopup={openProjectPopup}
          tabView={tabView}
          flowGroups={flowGroups}
          fetchAllgroups={fetchAllgroups}
          getIsLocked={getIsLocked}
          showAvailable={showAvailable}
          backs={backFromProject}
          breadcrumb={getBreadCrumb(RgroupId)}
        />
      )
    },
    currentAvailedPlan.toUpperCase() !== 'LEARNER' &&
    {
      name: 'Build Flows',
      id: 'Build',
      icon: 'BUILD_FLOW',
      onClickTab: value => {
        navigateTo(`/projects/flows/build`)
        addInLStorage(value)
      },
      component: (
        <ProjectsBuild
          data={buildProjects}
          RgroupId={RgroupId}
          checkoutBuildGroup={checkoutBuildGroup}
          openProjectPopup={openProjectPopup}
          tabView={tabView}
          flowGroups={flowGroups}
          fetchAllgroups={fetchAllgroups}
          getIsLocked={getIsLocked}
          showAvailable={showAvailable}
          backs={backFromProject}
          breadcrumb={getBreadCrumb(RgroupId)}
        />
      )
    }
  ]
    : []

  const createCheckoutSession = (showBuyGroupInfo) => {
    const url = `${window.REACT_APP_SELF_URL}/projects/flows/${active.toLowerCase()}/${RgroupId}`
    setBuyIsLoading(true)
    buyCourse(showBuyGroupInfo['spid'], url, url)
  }

  return (
    <div className="em-row">
      {myProfile &&
        myProfile.roles === 'owner' &&
        showBuyCourse &&
        isSubscriptionCheck &&
        !isSubscriptionCheck.isLoading &&
        !fetchAllgroups.isLoading &&
        data.length > 0 &&
        RgroupId &&
        showBuyGroupInfo &&
        showBuyGroupInfo['key'] &&
        RgroupId === showBuyGroupInfo['key'] &&
        <PopUp
          className={'buyNow_purchase'}
          width={'590px'}
          bottomButton={false}
          bottomButtonCancel={false}
          onCancel={hideShowBuyCourse}
        >
          {buyIsLoading === true && (<LoaderBGWithinComponent />)}
          <div className={'em-row'}>
            <div className="em-buyCourse-parent">
              <div className="em-buycourse-img">
                <img src={require('../../../images/buyNowpurchase.png')} />
              </div>
              <div className="em-buycourse-description">
                <span className="des_1"><b>{showBuyGroupInfo['title']}?</b></span>
                <span className="des_2">
                  <ul>
                    <li>Modules: {showBuyGroupInfo['modules']}</li>
                    <li>Hours : {showBuyGroupInfo['hours']}</li>
                  </ul>
                </span>
                <span className="des_2">You're just one step away from transforming your skills! Secure your place and start right away.</span>

                <span className="des_3">
                  <button className={'em-btn -p-t-buy'} onClick={() => createCheckoutSession(showBuyGroupInfo)}>
                    Proceed to Buy
                  </button>
                </span>

                <span className="des_4">
                  Note: If you have already purchased this course please refresh this page
                </span>
              </div>
            </div>
          </div>
        </PopUp>
      }
      {projectPopup === true && (
        <ProjectCreation
          flowId={flowId}
          theme={theme}
          dispatch={dispatch}
          flowDescrption={flowDescrption}
          SelectedFlow={flowTitle}
          createRecord={createRecord}
          setProjectPopup={() => {
            setProjectPopup(false)
            setupCreate(false)
          }}
          currenItem={currenItem}
          navigateTo={navigateTo}
          myProfile={myProfile}
          isBuild={isBuild}
          isSubscriptionCheck={isSubscriptionCheck}
          buyCourse={buyCourse}
          isLoadingUPlan={isLoadingUPlan}
          RgroupId={RgroupId}
          getAvailableUserRoles={getAvailableUserRoles}
          fetchAllgroups={fetchAllgroups}
          uPCreation={uPCreation}
          upCreate={upCreate}
          projectCreated={projectCreated}
          formDialogue={formDialogue}
          groupLoading={groupLoading}
          createProject={createProject}
          inviteUsertoProject={inviteUsertoProject}
          addProjectToGroup={addProjectToGroup}
          flowGroups={flowGroups}
          wGroupCreated={wGroupCreated}
          getIsLocked={getIsLocked}
          isAddedDone={isAddedDone}
          refreshGroups={refreshGroups}
        />
      )}

      <div className="em-row em-project-group">
        <CBreadCrumb bArray={getBreadCrumb(RgroupId).slice(0, -1)} />
      </div>

      {isSubscriptionCheck && isSubscriptionCheck.isLoading === true ? (
        <div className="em-row locenter">
          <LoaderBGWithinComponent />
        </div>
      ) : (
        <div className="em-row">
          {(buildProjects.length !== 0 || learnProjects.length !== 0) && !fetchAllgroups.isLoading && (
            <div className={`pr-switch-avail ${active === 'Build' ? 'build' : ''}`}>
              <FlipSwitch handleHideErrorIter={handleSwitch} label="Available" graph={true}></FlipSwitch>
            </div>
          )}
          <Tabs
            tabTitle={true}
            tabData={tabData}
            active={active}
            tabWidth={'fit-content'}
            key={projectFlowInfoKey}
            RgroupId={RgroupId}
          />
        </div>
      )}
    </div>
  )
}

const ProjectsLearn = ({
  openProjectPopup,
  data,
  tabView,
  flowGroups,
  RgroupId,
  fetchAllgroups,
  getIsLocked,
  showAvailable,
  backs,
  checkoutLearnGroup,
  breadcrumb
}) => {
  const groupsInside = groupProjects(data, RgroupId === null ? 'group_id' : 'DONTGROUP', 'noGroup')

  return (
    <>
      <div className="em-flex" style={{ justifyContent: 'space-between' }}>
        <h3 className="create-pr-headerline">{breadcrumb[breadcrumb.length - 1].title}</h3>
        <BackNavigation
          backs={() => backs()}
        />
      </div>
      <div className={`sample-projects customScrollDsgn ${tabView ? 'scrollHeight-projects' : ''}`}>
        {data.length === 0 && !fetchAllgroups.isLoading && (
          <div className="em-row locenter">
            <LoaderBGWithinComponent />
          </div>
        )}

        {data.length > 0 && (
          <>
            {(() => {
              let projects = []
              if (RgroupId === null) {
                Object.keys(groupsInside).forEach(key => {
                  if (key !== undefined && key !== null && key !== 'noGroup') {
                    projects.push(<Group item={flowGroups[key]} onClick={() => checkoutLearnGroup(key)} />)
                  }
                })
              } else {
                if (groupsInside['noGroup']) {
                  groupsInside['noGroup']
                    .filter(card => {
                      if (RgroupId !== null) {
                        return card.group_id === RgroupId
                      }
                      return true;
                    })
                    .map(card => {
                      const isLocked = getIsLocked(card)
                      if (showAvailable === true && isLocked === true) return
                      projects.push(
                        <Card
                          item={card}
                          onClick={() =>
                            openProjectPopup(card.value, card.label, card.flow_id, card.Description, card, false)
                          }
                          isLocked={isLocked}
                        />
                      )
                    })
                }
              }
              return projects.map(item => item)
            })()}
          </>
        )}
      </div>
    </>
  )
}

const ProjectsBuild = ({
  openProjectPopup,
  data,
  tabView,
  flowGroups,
  RgroupId,
  fetchAllgroups,
  getIsLocked,
  showAvailable,
  backs,
  checkoutBuildGroup,
  breadcrumb
}) => {

  const indus = [...new Set(data.map(i => i.industry).flat())]
  const industries = indus.map(l => {
    return { value: l, label: l }
  }, ...[{ value: 'All', label: 'All' }])

  const [selectedOption, setSelectedOption] = React.useState('All')


  const handleChange = selectedOption => {
    setSelectedOption(selectedOption.target.value)
  }

  const groupsInside = groupProjects(data, RgroupId === null ? 'group_id' : 'DONTGROUP', 'noGroup')

  return (
    <>
      <div className="project-flow main-container">
        {indus.length > 0 && (
          <div>
            <div className="selected-industry">Industry</div>
            <div className="select-container">
              <select className="custom-select" onChange={handleChange}>
                <option value={'All'}> {'All'}</option>
                {industries.map(i => {
                  return <option value={i.value}> {i.label}</option>
                })}
              </select>
            </div>
          </div>
        )}
      </div>

      <div className="em-flex" style={{ justifyContent: 'space-between' }}>
        <h3 className="create-pr-headerline">{breadcrumb[breadcrumb.length - 1].title}</h3>
        <BackNavigation
          backs={() => backs()}
        />
      </div>

      <div className={`sample-projects customScrollDsgn ${tabView ? 'scrollHeight-projects' : ''}`}>
        {data.length === 0 && !fetchAllgroups.isLoading && (
          <div className="em-row locenter">
            <LoaderBGWithinComponent />
          </div>
        )}
        {data.length > 0 && (
          <>
            {(() => {
              let projects = []
              const blank_project = data.find(i => i.flow_id === 'blank_project')
              if (RgroupId === null)
                Object.keys(groupsInside).forEach(key => {
                  if (key !== undefined && key !== null && key !== 'noGroup') {
                    const isPractice =
                      groupsInside[key] &&
                      groupsInside[key].filter(i =>
                        selectedOption === 'All' ? true : i.industry.includes(selectedOption)
                      )
                    if (isPractice && isPractice.length > 0)
                      projects.push(<Group item={flowGroups[key]} onClick={() => checkoutBuildGroup(key)} />)
                  }
                })
              if (groupsInside['noGroup']) {
                groupsInside['noGroup']
                  .filter(card => {
                    if (RgroupId !== null) {
                      return card.group_id === RgroupId
                    }
                    return true;
                  })
                  .filter(i => (selectedOption === 'All' ? true : i.industry.includes(selectedOption)))
                  .map(card => {
                    if (card.flow_id !== 'blank_project') {
                      const isLocked = getIsLocked(card)
                      if (showAvailable === true && isLocked === true) return
                      projects.push(
                        <Card
                          item={card}
                          onClick={() =>
                            openProjectPopup(card.value, card.label, card.flow_id, card.Description, card, true)
                          }
                          isLocked={isLocked}
                        />
                      )
                    }
                  })
              }
              projects.push(
                RgroupId === null &&
                <Card
                  item={blank_project}
                  onClick={() =>
                    openProjectPopup(
                      blank_project.value,
                      null,
                      blank_project.flow_id,
                      blank_project.Description,
                      blank_project,
                      true
                    )
                  }
                  isLocked={false}
                />
              )
              return projects.map(item => item)
            })()}
          </>
        )}
      </div>
    </>
  )
}

const ProjectCreation = ({
  createRecord,
  SelectedFlow,
  dispatch,
  flowId,
  flowDescrption,
  setProjectPopup,
  currenItem,
  navigateTo,
  myProfile,
  isBuild,
  isSubscriptionCheck,
  buyCourse,
  isLoadingUPlan,
  RgroupId,
  upCreate,
  fetchAllgroups,
  uPCreation,
  projectCreated,
  formDialogue,
  groupLoading,
  createProject,
  inviteUsertoProject,
  addProjectToGroup,
  flowGroups,
  wGroupCreated,
  getIsLocked,
  isAddedDone,
  refreshGroups
}) => {
  const handleTextInputChange = e => {
    setTextInput(e.target.value)
  }

  const handleDescriptionChange = e => {
    setDescription(e.target.value)
  }

  const [textInput, setTextInput] = React.useState(SelectedFlow)
  const [description, setDescription] = React.useState(flowDescrption)
  const [groupId, setGroupId] = React.useState('')
  const [showAdvanced, setShowAdvanced] = React.useState(false)

  const availedPlan =
    isSubscriptionCheck &&
      !isSubscriptionCheck.isLoading &&
      isSubscriptionCheck.subscribedProduct &&
      isSubscriptionCheck.subscribedProduct.name
      ? isSubscriptionCheck.subscribedProduct.name
      : 'NO_PLAN'

  const getUpgradedPlans = () => {
    if (currenItem['plan'] && currenItem['plan'].length > 0 && availedPlan) {
      const arr = currenItem['plan'].filter(item => item !== availedPlan)
      if (arr.length > 0) {
        let result = []
        for (let i = 0; i < arr.length - 1; i++) {
          result.push(arr[i])
          result.push(' Or ')
        }
        result.push(arr[arr.length - 1])
        return result
      }
    }
  }

  const upgradePlans = getUpgradedPlans()

  const IsEmpty = () => {
    dispatch(addNotification('Please fill in all required fields', 'danger', false))
    dispatch(showNotification(true))
  }

  const getAllGroups = () => {
    const groups =
      fetchAllgroups &&
      fetchAllgroups.groupList &&
      fetchAllgroups.groupList.reduce((acc, next) => {
        if (next['tags'].includes('USERS')) {
          return [...acc, { label: next.title, value: next.id, groupInfo: next }]
        } else {
          return acc
        }
      }, [])
    return groups
  }

  const groupInfoNotAvailable = () => {
    dispatch(addNotification('Please select a group.', 'danger', false))
    dispatch(showNotification(true))
  }

  const groupUsers0 = () => {
    dispatch(
      addNotification('Please select a different group, as there are no users in the current group.', 'danger', false)
    )
    dispatch(showNotification(true))
  }

  const createNewRecord = () => {
    if (showAdvanced === true && (groupId === '' || groupId === undefined)) {
      groupInfoNotAvailable()
    } else {
      if (
        groupId !== '' &&
        groupId !== undefined &&
        groupId['groupInfo'] &&
        groupId['groupInfo']['numberOfUsers'] === 0
      ) {
        groupUsers0()
        return
      } else
        createRecord({
          title: textInput,
          space_desc: description,
          flowId: flowId,
          roleName: 'member',
          groupId: groupId['value'],
          groupName: groupId['label'],
          showAdvanced: showAdvanced
        })
    }
  }
  const getGroupTitle = () => {
    const map = new Map(Object.entries(flowGroups))
    const currentGroup = map.get(RgroupId)
    if (!currentGroup) {
      const groupId = { title: RgroupId ? RgroupId.replaceAll('_', ' ') : currenItem['label'] }
      return groupId
    }
    return currentGroup
  }

  const checkOutToProjectPage = wGroupCreated => {
    console.log(wGroupCreated)
    const link = { pathname: '/projects', state: 'GOTOGROUP', value: { groupKey: RgroupId, groupId: wGroupCreated.id } }
    navigateTo(link)
    dispatch(setProjectInfoKey(new Date().getTime()))
  }

  const isLocked = getIsLocked(currenItem)

  const buyNowpurchase = isLocked === true && (!isBuild || isBuild) ? 'buyNow_purchase' : ''

  return (
    <PopUp className={buyNowpurchase} width={buyNowpurchase ? '590px' : '70%'} bottomButton={false} bottomButtonCancel={false} onCancel={() => setProjectPopup()} loadingCancel={true}>
      {formDialogue && !uPCreation && (
        <OverLayLodingInsideComponent message={' Your project is getting created, Please wait'} />
      )}
      {isLocked == true && isBuild ? (
        <>
          <div className="em-row">
            {isLoadingUPlan && <LoaderBGWithinComponent />}
            <div className="em-buyCourse-parent">
              <div className="em-buycourse-img">
                <img src={require('../../../images/buyNowpurchase.png')} />
              </div>
              <div className="em-buycourse-description">
                {myProfile.roles === 'owner' ? (
                  <span className="des_1">
                    <b>{getGroupTitle().title}</b> isn’t available in your <b>{availedPlan} plan.</b> To access it, please upgrade {' '}
                    {upgradePlans.length === 1 ? 'to' : ' either to '} <b>{upgradePlans && upgradePlans.join(' ')}</b> Plan{' '}
                  </span>
                ) : (
                  <span className="des_1">
                    <b>{getGroupTitle().title}</b> isn’t available in your <b>{availedPlan} plan.</b> To access it, please contact your admin for further
                    assistance.
                  </span>
                )}

                <span className="des_3">

                  {myProfile.roles === 'owner' ? (
                    <>
                      <button className={'em-btn later'} onClick={() => setProjectPopup()}>
                        Later
                      </button>
                      <button className={'em-btn -p-t-buy'} onClick={() => { navigateTo('/organizations/billings') }}>
                        Upgrade Plan
                      </button>
                    </>
                  )
                    :
                    <>
                      <button className={'em-btn later'} onClick={() => setProjectPopup()}>
                        Close
                      </button>
                    </>
                  }
                </span>
                <span className="des_4">
                  Note: If you have already upgraded, please refresh this page.
                </span>
              </div>
            </div>
          </div>
        </>
      ) : isLocked == true && !isBuild ? (
        <>
          <div className="em-row">
            {isLoadingUPlan && <LoaderBGWithinComponent />}
            <div className="em-buyCourse-parent">
              <div className="em-buycourse-img">
                <img src={require('../../../images/buyNowpurchase.png')} />
              </div>
              <div className="em-buycourse-description">
                {myProfile.roles === 'owner' ? (
                  <span className="des_1">
                    <b>{currenItem['label']}?</b> module isn’t accessible,  you can access it by purchasing following course {' '}
                  </span>
                ) : (
                  <span className="des_1">
                    <b>{currenItem['label']}?</b> module isn’t accessible, to access it, please contact your admin for further assistance.
                  </span>
                )}
                <span className="des_2">
                  <b>{getGroupTitle().title}</b><br />
                  <ul>
                    <li>Modules: {getGroupTitle().modules}</li>
                    <li>Hours : {getGroupTitle().hours}</li>
                  </ul>
                  <a href={currenItem.course_link ? currenItem.course_link : 'https://emlylabs.com/'} target="_blank">
                    {' '}
                    View Course Details&nbsp;&nbsp;<i class="fa fa-external-link" aria-hidden="true"></i>
                  </a>{' '}
                </span>

                <span className="des_3">

                  {myProfile.roles === 'owner' ? (
                    <>
                      <button className={'em-btn later'} onClick={() => setProjectPopup()}>
                        Later
                      </button>
                      <button className={'em-btn -p-t-buy'} onClick={() => buyCourse(currenItem.spid)}>
                        Proceed to Buy
                      </button>
                    </>
                  )
                    :
                    <>
                      <button className={'em-btn later'} onClick={() => setProjectPopup()}>
                        Close
                      </button>
                    </>
                  }
                </span>
                <span className="des_4">
                  Note: If you have already purchased this course please refresh this page.
                </span>
              </div>
            </div>
          </div>
        </>
      ) : uPCreation === true && upCreate === true ? (
        <>
          <CpInvite
            projectTitle={textInput}
            memberList={projectCreated}
            retryCreateProject={createProject}
            retryInviteUser={inviteUsertoProject}
            retryAddProjectToGroup={addProjectToGroup}
          />
          {isAddedDone &&
            <div className="em-row em-flex" style={{ justifyContent: 'flex-end', gap: '15px' }}>
              <NormalButton onClick={() => setProjectPopup()} style={{ marginTop: '15px' }} className="em-btn em-danger">
                Close
              </NormalButton>
              {formDialogue === false && (
                <NormalButton
                  onClick={() => checkOutToProjectPage(wGroupCreated)}
                  style={{ marginTop: '15px' }}
                  className="em-btn"
                >
                  Navigate to Group
                </NormalButton>
              )}
            </div>}
        </>
      ) : (
        <>
          {groupLoading && (
            <OverLayLodingInsideComponent message={''} />
          )}
          <ProjectCreationForm
            groupLoading={groupLoading}
            textInput={textInput}
            handleTextInputChange={handleTextInputChange}
            description={description}
            handleDescriptionChange={handleDescriptionChange}
            flowId={flowId}
            isBuild={isBuild}
            setGroupId={setGroupId}
            setShowAdvanced={setShowAdvanced}
            showAdvanced={showAdvanced}
            getAllGroups={getAllGroups}
            setProjectPopup={setProjectPopup}
            createNewRecord={createNewRecord}
            IsEmpty={IsEmpty}
            refreshGroups={refreshGroups}
          />
        </>
      )}
    </PopUp>
  )
}