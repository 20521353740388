import React from 'react'
import FlipSwitch from '../AppDetail/FlipSwitch'
import './projectForm.css'
import ProjectUtils from './ProjectUtils'

export function ProjectCreationForm(props) {
  return (
    <div className="em-row project-cr-form">
      <div className="em-row project-creation-form">
        <label htmlFor="text_input">Name</label>
        <input
          type="text"
          id="text_input"
          name="text_input"
          placeholder=""
          value={props.textInput}
          onChange={props.handleTextInputChange}
          disabled={!props.isBuild ? true : false}
          required

        />
        <label htmlFor="text_input">Description</label>
        <textarea
          id="description"
          name="description"
          rows="4"
          cols="50"
          placeholder=""
          value={props.description}
          onChange={props.handleDescriptionChange}
          disabled={!props.isBuild ? true : false}
        ></textarea>
        {props.flowId !== 'blank_project' && props.isBuild && (
          <label className="text_input-hint">
            Please note you can modify the above fields according to your requirements.
          </label>
        )}
      </div>
      {props.getAllGroups !== null &&
        <div className="em-row em-flex group-info">
          {!props.isBuild && (
            <>
              <div className="em-row f-switch">
                <FlipSwitch
                  handleHideErrorIter={value => {
                    if (value === false) {
                      props.setGroupId('')
                    }
                    props.setShowAdvanced(value)
                  }}
                  label="Group Invite"
                  graph={true}
                />
              </div>
              {props.showAdvanced && (
                <div className="em-row projectutils group-sets">
                  <ProjectUtils userGroups={props.getAllGroups()} setGroupId={value => props.setGroupId(value)} refreshGroups={props.refreshGroups} />
                  <button
                    onClick={() => {
                      props.refreshGroups()
                    }}
                    className="group-refresh-button"
                  >
                    <i class="fa fa-refresh i-action"></i>
                  </button>
                </div>
              )}
            </>
          )}
        </div>
      }
      <div className="em-row" style={{ marginTop: 20, display: 'flex', gap: '10px', justifyContent: 'flex-end' }}>
        <button
          className={'project-button em-danger ufont'}
          style={{ border: 'none' }}
          onClick={() => {
            props.setProjectPopup()
          }}
        >
          Cancel
        </button>
        <button
          className={
            props.textInput !== null ? 'em-btn project-button ufont' : 'em-btn project-button-continue-disabled ufont'
          }
          onClick={() => {
            if (props.textInput !== '') {
              props.createNewRecord()
            } else {
              props.IsEmpty()
            }
          }}
        >
          Create
        </button>
      </div>
    </div>
  )
}
